import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import axios from 'axios';

const About = () => {
    const [employes, setEmployes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://snk.templateapi.xyz/employe')
                .then((e) => {
                    setIsLoading(false);
                    setEmployes(e.data.data);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    if (isLoading) {
        return <div className='loader-container'><div className="loader"></div></div>
    }

    return (
        <div className='flex flex-col items-center justify-center gap-3 pb-10'>
            <Header />
            <h2 className='text-[30px] font-bold mt-10'>Манай хамт олон</h2>
            <p className='w-[40vw] max-md:w-[90vw]'>Big Cartel are creative people who help creative people. The guys have funniest private videos compressed to half-dozen seconds. Sometimes it is a collage from various videos, sometimes slow-motioned web-selfies, sometimes a picture of an abandoned street. But always extremely dazzling.</p>
            <div className='grid grid-cols-4 gap-40 mt-20 max-md:grid-cols-2 max-md:justify-items-center max-md:pl-5'>
                {employes.map((e) => (
                    <div>
                        <h2 className='text-[17px] font-semibold'>{e.about}</h2>
                        <p>{e.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default About
