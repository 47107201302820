import React from 'react'
import Header from '../../Components/Header'
import ProductCard from '../../Components/ProductCard'

const Product = ({ products }) => {
    return (
        <div className='bg-[#fafafa] min-h-screen'>
            <Header />
            <div className='grid grid-cols-5 w-screen mb-[20px] justify-items-center items-start mt-[50px] gap-10 px-[10vw]
             max-md:grid-cols-2'>
                {products.map((e) => (
                    <ProductCard cover={e.cover} title={e.productName} price={e.price} id={e._id} />
                ))}
            </div>
        </div>
    )
}

export default Product
