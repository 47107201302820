import React from 'react'
import png from '../../assets/home.png'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { Link } from 'react-router-dom';

const Home = ({ products }) => {
    return (
        <div>
            <div className="bg-[#20412D] flex justify-around items-center min-h-[70vh]">
                <div className='flex flex-col gap-20 w-[50vw] mt-[80px] max-md:w-screen max-md:items-center max-md:justify-center'>
                    <h2 className='text-[50px] font-semibold text-slate-50 max-md:text-[40px] max-md:text-[center] max-md:pl-5'>
                        “Бид үргэлж эрүүл мэнд, гоо сайхан болон аз жаргалыг эрхэмлэдэг”
                    </h2>
                    <div className='flex items-center gap-5 max-md:flex-col max-md:gap-0 max-md:items-start max-md:justify-center'>
                        <p className='w-[130px] text-slate-50 font-bold text-[19px] max-md:w-[300px] max-md:px-2'>
                            Онцлох бүтээгдэхүүн
                        </p>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={3}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            className='h-[250px] w-[50vw] max-md:w-screen '
                        >
                            {products.map((e) =>
                            (
                                <SwiperSlide>
                                    <Link to={'/product/' + e._id} style={{ cursor: 'pointer' }}>
                                        <div className='flex items-center gap-2 bg-[#fdfbfbbb] absolute rounded-md p-2 w-[200px] h-[120px] mt-[55px] max-md:mt-[15px] max-md:w-[140px] max-md:ml-[5px]'></div>
                                        <div className='flex items-center gap-2 absolute ml-[10px] mt-[50px] max-md:flex-col max-md:mt-[10px]'>
                                            <img className='w-[75px] h-[100px]' src={"https://snkapi.templateapi.xyz/uploads/" + e.cover} alt="" />
                                            <div className=''>
                                                <p className=''>{e.productName}</p>
                                                <p>{Intl.NumberFormat('en-us').format(e.price)}₮</p>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div>
                    {window.innerWidth > 720 ? <img src={png} alt="" className='h-[60vh]' /> : null}
                </div>
            </div>
            <div className='flex justify-evenly my-[50px] min-h-[50vh] max-md:flex-col max-md:justify-center max-md:items-center'>
                <div>
                    <div className='h-[350px] w-[300px] bg-[#20412D] absolute -z-10 ml-[-20px] mt-[40px] rounded-[350px] max-md:ml-[10px]'></div>
                    <img src="https://images.everydayhealth.com/images/healthy-living/about-us-hero-alt.jpg?sfvrsn=68592040_1" alt=""
                        className='w-[400px] h-[200px] mt-[120px] rounded-md'
                    />
                </div>
                <div className='w-[30vw] flex flex-col gap-10 items-center max-md:w-[80vw] max-md:mt-[100px] max-md:gap-2'>
                    <h2 className='font-semibold text-[30px]'>Title</h2>
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatem numquam earum totam minus ipsum, quae quisquam consectetur iusto eum voluptatum incidunt, voluptates blanditiis. A, officiis quos. Accusantium at suscipit reiciendis sapiente tempore sint incidunt. Cupiditate eius maxime tenetur, perferendis non quibusdam fuga ipsam laudantium, error harum placeat debitis? Perferendis similique blanditiis corrupti magnam aut culpa, perspiciatis voluptas in quas id sed ratione, cumque ex laudantium, minima quasi repellat tenetur atque aliquid! Officia nisi aut quia magni, dolor maxime labore. Molestias quas cupiditate iste eligendi laudantium enim qui vero, magnam ad sapiente, consequuntur, mollitia est voluptas facilis praesentium suscipit dolor perferendis?</p>
                </div>
            </div>
        </div>
    )
}

export default Home
