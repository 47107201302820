import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../../Components/Header';

const NewsDetial = ({ importNews }) => {
    const [news, setNews] = useState({});
    const { id } = useParams();

    useEffect(() => {
        setNews(importNews.find((e) => e._id === id));
    })

    return (
        <div className='flex flex-col items-center justify-center gap-10 max-md:gap-5'>
            <Header text={news.title} />
            {/* <h2 className='text-[28px] font-semibold'>{news.title}</h2> */}
            <img src={'https://snkapi.templateapi.xyz/uploads/' + news.cover} className='w-[10vw] h-[20vw] max-md:w-[70vw] max-md:h-auto' alt="" />
            <div className='w-[60vw] text-center max-md:w-[80vw]' dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
    )
}

export default NewsDetial
