import React, { useEffect, useState } from 'react'
import Header from '../../../Components/Header'
import { useParams } from 'react-router-dom';

const ProductDetial = ({ products }) => {
    const [product, setProduct] = useState({});
    const { id } = useParams();

    useEffect(() => {
        setProduct(products.find((e) => e._id === id));
    })

    return (
        <div className='flex flex-col items-center justify-center pb-10 gap-10'>
            <Header />
            <h2 className='text-[28px] font-semibold'>Бүтээгдэхүүний тухай</h2>
            <div className='flex gap-10 max-md:flex-col max-md:items-center'>
                <div className='w-[40vw] flex items-center justify-center'>
                    <img className='w-[20vw] max-h-[100vh] rounded-sm max-md:w-[70vw] max-md:h-auto'
                        src={"https://snkapi.templateapi.xyz/uploads/" + product.cover} alt=""
                    />
                </div>
                <div className='flex flex-col gap-10 max-md:gap-5'>
                    {/* <div className='w-[40vw] max-md:w-[80vw]' dangerouslySetInnerHTML={{ __html: product.description }} /> */}
                    <p className='w-[40vw] max-md:w-[80vw]'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus neque sed suscipit, perspiciatis temporibus, molestiae porro voluptatum voluptatibus at nobis quibusdam! Dignissimos culpa qui id praesentium, nemo animi quis quae?</p>
                    <p className='border-[1px] text-[#20412D] border-[#20412D] w-[max-content] px-3'>{Intl.NumberFormat('en-us').format(product.price)}₮</p>
                </div>
            </div>
        </div>
    )
}

export default ProductDetial
