import React from 'react';
import cover from '../assets/cover.jpeg';

const Header = ({ text }) => {
    return (
        <div
            style={{
                backgroundImage: `url(${cover})`,
                width: '100vw',
                height: '45vh',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                textAlign: 'center',
            }}
            className="text-center"
        >
            <p className='text-[40px] font-semibold pt-[220px] text-[#fff] z-20'>
                {text ? text : 'SNK Эрүүл Мэнд'}
            </p>
        </div>
    );
}

export default Header;
