import React from 'react'
import Header from '../../Components/Header'
import logo from '../../assets/logo.png'
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareThreads } from "react-icons/fa6";

const Members = () => {
    return (
        <div className='flex flex-col justify-center w-screen items-center gap-14 pb-10'>
            <Header />
            <div className='bg-[#fff] rounded-md shadow-md shadow-[#838383] flex gap-10 justify-center items-center  max-md:flex-col max-md:py-3'>
                <div className='flex flex-col gap-5 p-5 text-center '>
                    <h2 className='text-[22px] font-semibold'>Гишүүн нэвтрэх</h2>
                    <input placeholder='Утасны дугаар*' type="text" name="" id="" />
                    <input placeholder='ID*' type="text" name="" id="" />
                    <input placeholder='Нууц үг*' type="password" name="" id="" />
                    <button className='bg-[#20412D] w-[310px] text-[#fff] rounded-[20px] py-1'>Нэвтрэх</button>
                </div>
                <div className='w-[330px] flex flex-col justify-start items-center bg-[#20412D] p-5 gap-5'>
                    <img src={logo} className='w-[100px] h-[100px]' alt="" />
                    <div className='flex gap-2'>
                        <IoMdMail size={35} color='#fff' />
                        <div className='flex flex-col gap-0'>
                            <p className='text-[15px] text-[#fff]'>И-Мэйл</p>
                            <p className='text-[12px] w-[180px] text-[#838383]'>snkwellness@gmail.com</p>
                        </div>
                    </div>
                    <div className='border-[1px] w-[250px] border-[#d3d3d3]'></div>
                    <div className='flex gap-2'>
                        <IoCall size={35} color='#fff' />
                        <div className='flex flex-col gap-0'>
                            <p className='text-[15px] text-[#fff]'>Утасны дугаар</p>
                            <p className='text-[12px] w-[180px] text-[#838383]'>+976 99335781</p>
                        </div>
                    </div>
                    <div className='border-[1px] w-[250px] border-[#d3d3d3]'></div>
                    <div className='flex gap-2 justify items-start'>
                        <FaLocationDot size={35} color='#fff' />
                        <div className='flex flex-col gap-0'>
                            <p className='text-[15px] text-[#fff]'>Хаяг</p>
                            <p className='text-[12px] w-[180px] text-[#838383]'>Сансрын И-Мартаас сансрын колонк орох замд Nesora Tower 209 тоот</p>
                        </div>
                    </div>
                    <div className='border-[1px] w-[250px] border-[#d3d3d3]'></div>
                    <div className='flex gap-3 justify-start items-start w-[200px]'>
                        <a href="https://www.facebook.com/snkwellnessmn">
                            <FaFacebook size={30} color='#fff' />
                        </a>
                        <a href="https://www.instagram.com/snkeruul/">
                            <FaSquareInstagram size={30} color='#fff' />
                        </a>
                        <a href="https://www.threads.net/@snkeruul?xmt=AQGz4N3Oa0nGdIPpkYJZsMJboWbIjOF-mLMAlRsrrb9UUJM">
                            <FaSquareThreads size={30} color='#fff' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Members
