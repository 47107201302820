import React, { useState } from 'react'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { PiCrownSimpleBold } from "react-icons/pi";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className='flex items-center justify-between px-20 py-3 rounded-bl-3xl rounded-br-3xl absolute w-[100vw] bg-[#fff] max-md:px-4'>
            <div className='flex gap-3 font-semibold'>
                <h2 className='text-[21px]'>SNK Wellness</h2>
                <img src={logo} className='w-[30px] h-[30px]' alt="" />
            </div>
            {
                window.innerWidth > 720 ?
                    <div className='flex gap-5 font-semibold items-center justify-center'>
                        <Link className='flex items-center justify-center gap-2 bg-[] border-[#fbcd04] border-2 text-[#fbcd04] px-3 py-1 text-[14px] rounded-[30px]'>
                            <PiCrownSimpleBold size={25} />
                            <a href='https://member.snkwellness.mn'>SNK гишүүд</a>
                        </Link>
                        <Link to={'/'} className='hover'>Нүүр хуудас</Link>
                        <Link to={'/about'} className='hover'>Бидний тухай</Link>
                        <Link to={'/product'} className='hover'>Бүтээгдэхүүн</Link>
                        <Link to={'/news'} className='hover'>Мэдээлэл</Link>
                        <Link to={'/contact'} className='hover'>Холбоо барих</Link>
                    </div> :
                    !isOpen ?
                        <IoMenu color='black' size={30} onClick={() => setIsOpen(true)} />
                        :
                        <div>
                            <IoMdClose color='black' size={30} onClick={() => setIsOpen(false)} />
                            <div className='absolute flex flex-col ml-[-100px] mt-[15px] bg-[#fff] p-3 rounded-md gap-5 text-[17px]'>
                                <Link className='flex items-center justify-center gap-2 bg-[] border-[#fbcd04] border-2 text-[#fbcd04] px-3 py-1 text-[14px] rounded-[30px]'>
                                    <PiCrownSimpleBold size={25} />
                                    <a href='https://member.snkwellness.mn'>SNK гишүүд</a>
                                </Link>
                                <Link to={'/'} className='hover' onClick={() => setIsOpen(false)}>Нүүр хуудас</Link>
                                <Link to={'/about'} className='hover' onClick={() => setIsOpen(false)}>Бидний тухай</Link>
                                <Link to={'/product'} className='hover' onClick={() => setIsOpen(false)}>Бүтээгдэхүүн</Link>
                                <Link to={'/news'} className='hover' onClick={() => setIsOpen(false)}>Мэдээлэл</Link>
                                <Link to={'/contact'} className='hover' onClick={() => setIsOpen(false)}>Холбоо барих</Link>
                            </div>
                        </div>
            }
        </div>
    )
}

export default Navbar
