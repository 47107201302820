import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Page/Home/Home';
import Product from './Page/Product/Product';
import About from './Page/About/About';
import News from './Page/News/News';
import Contact from './Page/Contact/Contact';
import ProductDetial from './Page/Product/Detial/ProductDetial';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NewsDetial from './Page/News/Detial/NewsDetial';
import Members from './Page/Members/Members';

function App() {
  const [news, setNews] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = 'https://snkapi.templateapi.xyz';

    if (isLoading) {
      Promise.all([
        axios.get(url + '/news'),
        axios.get(url + '/product')
      ])
        .then(([newsResponse, productsResponse]) => {
          setNews(newsResponse.data.data);
          setProducts(productsResponse.data.data);
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  }, [isLoading]);

  if (isLoading) {
    return <div className='loader-container'><div className="loader"></div></div>
  }

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home products={products.filter((e) => e.special === true)} />} />
        <Route path='/product' element={<Product products={products} />} />
        <Route path='/about' element={<About />} />
        <Route path='/news' element={<News news={news} />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/product/:id' element={<ProductDetial products={products} />} />
        <Route path='/news/:id' element={<NewsDetial importNews={news} />} />
        <Route path='/members' element={<Members />} />
      </Routes>
    </Router>
  );
}

export default App;
