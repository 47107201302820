import React from 'react'
import { Link } from 'react-router-dom'

const ProductCard = ({ title, cover, price, id, news }) => {
    return (
        <Link to={news ? '/news/' + id : '/product/' + id}>
            <div className='bg-[#fff] w-[max-content] p-[2px] shadow-lg shadow-black-500/50 rounded-lg  border-[#333]'>
                <img className='w-[200px] h-[250px] rounded-md max-md:w-[170px] max-md:h-[210px]' src={"https://snkapi.templateapi.xyz/uploads/" + cover} alt="" />
                <p className='text-[17px] w-[200px] p-2 truncate md max-md:w-[170px]'>{title}</p>
                {news ? null : <p className='text-[#838383] px-2'>{Intl.NumberFormat('us-en').format(price)}₮</p>}
            </div>
        </Link>
    )
}

export default ProductCard
