import React from 'react'
import Header from '../../Components/Header'
import logo from '../../assets/logo.png'
import ProductCard from '../../Components/ProductCard'

const News = ({ news }) => {
    return (
        <div className='flex flex-col items-center justify-center gap-10 pb-10 max-md:text-center max-md:gap-5'>
            <Header />
            <h2 className='text-[30px] font-bold mt-10 max-md:text-[25px]'>Бүтээгдэхүүний мэдээлэл, онцлог, давуу тал</h2>
            <p className='w-[40vw] text-center max-md:w-[80vw]'>SNK бүтээгдэхүүнүүд нь Солонгосын үйлдвэрүүдтэй шууд гэрээ байгуулан эрүүл мэнд, гоо сайханд тулгуурладаг. Бид Монголын гишүүд, хэрэглэгчдэд бараа бүтээгдэхүүнийг хямд үнээр хэрэглэж, бизнес эрхлэгчдэд өндөр ашиг олох боломжийг олгож байна.</p>
            <img className='w-[120px] h-[120px]' src={logo} alt="" />
            <div className='grid grid-cols-3 w-[60vw] justify-items-center mt-[50px] gap-10'>
                {news.map((e) => (
                    <ProductCard title={e.title} cover={e.cover} news={true} id={e._id} />
                ))}
            </div>
        </div>
    )
}

export default News
